#full-screen-loading-overlay {
  z-index: 111111;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: rgba(0,0,0,0.8);
}

#full-screen-loading-overlay > .loader {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

#full-screen-loading-overlay > .loader > svg {
  animation-duration: 0.75s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: spinner-border;
}

.price-row > .price-col > .card {
  height: 92% !important;
  padding-bottom: 120px;
}

.pull-down {
  position: absolute;
  bottom: 30px;
  width: 100%;
  padding: 0 30px;
}

.StripeElement {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.375rem + 2px);
  padding: 0.6875rem 0.75rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  color: #555A64;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #EAEDF2;
  border-radius: 0.1875rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.StripeElement--focus {
  color: #555A64;
  background-color: #fff;
  border-color: #444F50;
  outline: 0;
  -webkit-box-shadow: none, 0 0 0 0.2rem rgba(68, 79, 80, 0.25);
  box-shadow: none, 0 0 0 0.2rem rgba(68, 79, 80, 0.25);
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
