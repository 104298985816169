.container {
    max-width: 358px;
}

.mw-half-container {
    max-width: 179px !important;
}

.h-101 {
    height: 101% !important;
}

.about_us-sidit_logo {
    width: 76px;
}

.outline-none {
    outline: none !important;
}

.pb-6 {
    padding-bottom: 6rem;
}

p, h1, h2, h3, h4, h5, h6 {
    margin-bottom: unset;
}

@media (min-width: 576px) {
    .mw-half-container {
        max-width: 250px !important;
    }
    .container {
        max-width: 500px;
    }
}

@media (min-width: 768px) {
    .mw-half-container {
        max-width: 334px !important;
    }

    .mw-md-half-container {
        max-width: 334px !important;
    }

    .py-md-6 {
        padding-bottom: 6rem !important;
        padding-top: 6rem !important;
    }
    .pt-md-6 {
        padding-top: 6rem !important;
    }
    .pb-md-6 {
        padding-bottom: 6rem !important;
    }
    .container {
        max-width: 668px;
    }

    .about_us-sidit_logo {
        width: 102px;
    }
}

@media (min-width: 992px) {
    .mw-half-container {
        max-width: 475px !important;
    }

    .mw-md-half-container {
        max-width: 475px !important;
    }

    .mw-lg-half-container {
        max-width: 475px !important;
    }

    .pb-lg-6 {
        padding-bottom: 6rem !important;
    }

    .pt-lg-6 {
        padding-top: 6rem !important;
    }

    .py-lg-6 {
        padding-bottom: 6rem !important;
        padding-top: 6rem !important;
    }
    .container {
        max-width: 950px;
    }

    .about_us-sidit_logo {
        width: 129px;
    }
}

@media (min-width: 1200px) {
    .mw-half-container {
        max-width: 500px !important;
    }

    .mw-md-half-container {
        max-width: 500px !important;
    }
    .mw-lg-half-container {
        max-width: 500px !important;
    }
    .container {
        max-width: 1000px;
    }
    .about_us-sidit_logo {
        width: 195px;
    }
}

@media (min-width: 1280px) {
    .mw-half-container {
        max-width: 600px !important;
    }
    .mw-md-half-container {
        max-width: 600px !important;
    }
    .mw-lg-half-container {
        max-width: 600px !important;
    }
    .container {
        max-width: 1200px;
    }
}


.flickery-yellow-dots {
    .dot {
        border: 2px solid #FFED00;
        border-radius: 999px;
        width: 10px;
        height: 10px;
        background: transparent;
        opacity: 1;

        &.is-selected {
            background-color: #FFED00;
            transform: none;
        }
    }
}
