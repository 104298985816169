// Body
$body-bg: #f3f3f3;

// Typography
//$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66D9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #FFED00;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$white: $body-bg;

$primary: #444F50;
$primary-fade: rgba(68, 79, 80, 0.7);
$secondary: #ffed00;
$secondary-fade: rgba(255, 237, 0, 0.5);
$info: $blue;

$grey-background: #f5f5f5;

$fa-font-path: "/webfonts";
