@import "variables";
//@import "nova";
@import "prices";
@import "nova_font";
@import "about_us";
@import "font_style";

a {
    color: $primary;
}

.v-html-body {
    h2 {
        margin-top: 1em;
    }

    p {
        margin-top: 0.5em;
    }
}

.question::before {
    content: "\2022";
    color: yellow;
    padding: 0.5rem;
}

mark {
    background-image: linear-gradient(120deg, $secondary-fade 0%, $secondary-fade 100%)
}

@media (max-width: 470px) {
    .display-3 {
        font-size: 28px;
    }
}

@media (max-width: 991px) {
    .price-row > .price-col > .card {
        height: 100%
    }
}

@media(max-width: 1780px) {
    .display-3 {
        font-size: 49px;
    }
}

.btn-primary {
    color: $primary !important;
    text-shadow: none;

    svg {
        color: $primary-fade;
    }

    &:hover {
        color: $primary-fade;

        svg {
            color: $primary-fade;
        }
    }
}

.btn-icon {
    color: $primary !important;
    text-shadow: none;

    svg {
        color: $primary-fade;
    }

    &:hover {
        color: $primary-fade;

        svg {
            color: $primary-fade;
        }
    }
}

.btn-outline {
    color: $primary !important;
    border-color: $primary;

    &:hover {
        border-color: rgba($primary, 0.5);
        color: $primary-fade;
    }
}

.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.reference-img{
    max-width: 240px;
    max-height: 120px;
}

.flex-column [data-flickity].flex-fill .flickity-viewport {
    flex: 1 1 auto;
    height: 100% !important;
}

.flex-column [data-flickity].flex-fill .carousel-cell {
    height: 100%;
}

.flex-column [data-flickity].flex-fill img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.testimonial:hover div{
    visibility: visible;
}

.testimonial:hover div {
    opacity: 1;
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
}
.testimonial-text {
    opacity: 0;
}

.testimonial:hover .img {
    opacity: 0;
    display: none;
    animation: fadeOut 0.5s;
    -webkit-animation: fadeOut 0.5s;
    -moz-animation: fadeOut 0.5s;
    -o-animation: fadeOut 0.5s;
}
@keyframes fadeOut {
    0% {opacity: 1;}
    100% {
        opacity: 0;
        display: none
    }
}

@-moz-keyframes fadeOut {
    0% {opacity: 1;}
    100% {
        opacity: 0;
        display: none
    }
}

@-o-keyframes fadeOut {
    0% {opacity: 1;}
    100% {
        opacity: 0;
        display: none
    }
}

@-webkit-keyframes fadeOut {
    0% {opacity: 1;}
    100% {
        opacity: 0;
        display: none
    }
}

@keyframes fadeIn {
    0% {opacity: 0; }
    100% {opacity: 1; display: block}
}

@-moz-keyframes fadeIn {
    0% {opacity: 0; }
    100% {opacity: 1; display: block}
}
@-webkit-keyframes fadeIn {
    0% {opacity: 0; }
    100% {opacity: 1; display: block}
}

@-o-keyframes fadeIn {
    0% {opacity: 0; }
    100% {opacity: 1; display: block}
}

.legal-document{
    p{
        margin-bottom: revert;
    }
}
