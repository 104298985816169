/* nunito-200 - latin */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/nunito-v12-latin-200.eot'); /* IE9 Compat Modes */
    src: local('Nunito ExtraLight'), local('Nunito-ExtraLight'),
    url('../fonts/nunito-v12-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/nunito-v12-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/nunito-v12-latin-200.woff') format('woff'), /* Modern Browsers */
    url('../fonts/nunito-v12-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/nunito-v12-latin-200.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-200italic - latin */
@font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 200;
    src: url('../fonts/nunito-v12-latin-200italic.eot'); /* IE9 Compat Modes */
    src: local('Nunito ExtraLight Italic'), local('Nunito-ExtraLightItalic'),
    url('../fonts/nunito-v12-latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/nunito-v12-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/nunito-v12-latin-200italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/nunito-v12-latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/nunito-v12-latin-200italic.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-300 - latin */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/nunito-v12-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Nunito Light'), local('Nunito-Light'),
    url('../fonts/nunito-v12-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/nunito-v12-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/nunito-v12-latin-300.woff') format('woff'), /* Modern Browsers */
    url('../fonts/nunito-v12-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/nunito-v12-latin-300.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-300italic - latin */
@font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/nunito-v12-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local('Nunito Light Italic'), local('Nunito-LightItalic'),
    url('../fonts/nunito-v12-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/nunito-v12-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/nunito-v12-latin-300italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/nunito-v12-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/nunito-v12-latin-300italic.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-regular - latin */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/nunito-v12-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Nunito Regular'), local('Nunito-Regular'),
    url('../fonts/nunito-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/nunito-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/nunito-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/nunito-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/nunito-v12-latin-regular.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-600 - latin */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/nunito-v12-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Nunito SemiBold'), local('Nunito-SemiBold'),
    url('../fonts/nunito-v12-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/nunito-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/nunito-v12-latin-600.woff') format('woff'), /* Modern Browsers */
    url('../fonts/nunito-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/nunito-v12-latin-600.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-italic - latin */
@font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/nunito-v12-latin-italic.eot'); /* IE9 Compat Modes */
    src: local('Nunito Italic'), local('Nunito-Italic'),
    url('../fonts/nunito-v12-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/nunito-v12-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/nunito-v12-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/nunito-v12-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/nunito-v12-latin-italic.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-600italic - latin */
@font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 600;
    src: url('../fonts/nunito-v12-latin-600italic.eot'); /* IE9 Compat Modes */
    src: local('Nunito SemiBold Italic'), local('Nunito-SemiBoldItalic'),
    url('../fonts/nunito-v12-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/nunito-v12-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/nunito-v12-latin-600italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/nunito-v12-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/nunito-v12-latin-600italic.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-800 - latin */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/nunito-v12-latin-800.eot'); /* IE9 Compat Modes */
    src: local('Nunito ExtraBold'), local('Nunito-ExtraBold'),
    url('../fonts/nunito-v12-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/nunito-v12-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/nunito-v12-latin-800.woff') format('woff'), /* Modern Browsers */
    url('../fonts/nunito-v12-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/nunito-v12-latin-800.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-800italic - latin */
@font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 800;
    src: url('../fonts/nunito-v12-latin-800italic.eot'); /* IE9 Compat Modes */
    src: local('Nunito ExtraBold Italic'), local('Nunito-ExtraBoldItalic'),
    url('../fonts/nunito-v12-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/nunito-v12-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/nunito-v12-latin-800italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/nunito-v12-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/nunito-v12-latin-800italic.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-900italic - latin */
@font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 900;
    src: url('../fonts/nunito-v12-latin-900italic.eot'); /* IE9 Compat Modes */
    src: local('Nunito Black Italic'), local('Nunito-BlackItalic'),
    url('../fonts/nunito-v12-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/nunito-v12-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/nunito-v12-latin-900italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/nunito-v12-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/nunito-v12-latin-900italic.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-900 - latin */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/nunito-v12-latin-900.eot'); /* IE9 Compat Modes */
    src: local('Nunito Black'), local('Nunito-Black'),
    url('../fonts/nunito-v12-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/nunito-v12-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/nunito-v12-latin-900.woff') format('woff'), /* Modern Browsers */
    url('../fonts/nunito-v12-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/nunito-v12-latin-900.svg#Nunito') format('svg'); /* Legacy iOS */
}
