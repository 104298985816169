h2 {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    color: #707070;
}

h1 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
}

p, .paragraph-text {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
}

h3 {
    font-size: 16px;
    line-height: 21px;
    font-weight: 600;
}

@media (min-width: 768px) {
    h2 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
    }

    h1 {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
    }


    h3 {
        font-size: 16px;
        line-height: 21px;
        font-weight: 600;
    }

    p, .paragraph-text {
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
    }
}

@media (min-width: 992px) {
    h2 {
        font-size: 20px;
        line-height: 30px;
        font-weight: 400;
    }


    h1 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
    }


    h3 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
    }

    p, .paragraph-text {
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
    }
}

@media (min-width: 1200px) {
    h2 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
    }

    h1 {
        font-size: 30px;
        line-height: 39px;
        font-weight: 600;
    }


    h3 {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
    }

    p, .paragraph-text {
        font-size: 20px;
        line-height: 30px;
        font-weight: 400;
    }
}

@media (min-width: 1280px) {
    h2 {
        font-size: 30px;
        line-height: 39px;
        font-weight: 400;
    }

    p, .paragraph-text {
        font-size: 20px;
        line-height: 30px;
        font-weight: 400;
    }

    h3 {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
    }

    h1 {
        font-size: 48px;
        line-height: 63px;
        font-weight: 600;
    }
}
